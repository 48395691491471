@mixin bgimg($filename, $imgbreakpoints...) {
  $at1x_path: "../images/#{$filename}_w_480.jpg";
  $at2x_path: "../images/#{$filename}_w_480@2x.jpg"; 
  $lastbreakpoint: 480;
  background-image: url("#{$at1x_path}");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; 
  @media 
    only screen and (-webkit-min-device-pixel-ratio: 1.5), 
    only screen and (min-resolution: 144dpi) { 
      background-image: url("#{$at2x_path}");
  }
  @each $breakpoint in $imgbreakpoints {
    @media all and (min-width: #{$lastbreakpoint}px) and (max-width: #{$breakpoint}px) {
      $at1x_path: "../images/#{$filename}_w_#{$breakpoint}.jpg";
      $at2x_path: "../images/#{$filename}_w_#{$breakpoint}@2x.jpg"; 
      background-image: url("#{$at1x_path}");
    }    
    @media 
      only screen and (min-width: #{$lastbreakpoint}px) and (max-width: #{$breakpoint}px) and (-webkit-min-device-pixel-ratio: 1.5), 
      only screen and (min-width: #{$lastbreakpoint}px) and (max-width: #{$breakpoint}px) and (min-resolution: 144dpi) { 
        background-image: url("#{$at2x_path}");
    }
    $lastbreakpoint: $breakpoint;
  }
  @media all and (min-width: 1201px) {
    background-image: url("../images/#{$filename}_w_1920.jpg")
  }  
  @media 
    only screen and (min-width: 1201px) and (-webkit-min-device-pixel-ratio: 1.5), 
    only screen and (min-width: 1201px) and (min-resolution: 144dpi){ 
      background-image: url("../images/#{$filename}_w_1920@2x.jpg")
  }
}