.carousel-slide {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
}
.slide1 {
  @include bgimg('homeslide1', 768, 992, 1200);
}
.slide2 {
  @include bgimg('homeslide2', 768, 992, 1200);
  background-position: 10% 50%;
}
.slide3 {
  @include bgimg('homeslide3', 768, 992, 1200);
}
.slide4 {
  @include bgimg('homeslide4', 768, 992, 1200);
}
.home-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 190;
}