.contact-bar {
  padding-top: 3.9375rem; // 63/16
  padding-bottom: 2.0625rem; // 33/16
  @media all and (min-width: $desktop-breakpoint-1) {
    padding-top: 5.8125rem; // 93/16
    padding-bottom: 8.3125rem; // 133/16
  }
  background-color: rgba($ks_lt_blue, 0.1);
  h3 {
    margin-top: 0;
  }
}
.contact-bar-description {
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.666666667;
  font-size: 0.9375rem; // 15/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    font-size: 1.125rem; // 18/16
  }
}
form {
  width: 100%;
  input[type="text"], input[type="tel"], input[type="email"], input[type="url"], textarea {
    border-radius: 0;
    width: 100%;
    display: block;
    margin-bottom: 0.5em;
    border: 0;
    outline: none;
    padding: 1em;
    background-color: rgba($ks_lt_blue, 0.2);
    color: $ks_dk_blue;
    &::placeholder {
      color: rgba($ks_dk_blue, 0.8);
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-weight: 900;
    }
  }
  textarea {
    max-height: 10em;
    resize: none;
  }
  .btn {
    margin-top: 0;
  }
}
.acceptance-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  span {
    font-weight: 300;
    letter-spacing: 0.05em;
    font-size: 0.9375rem; // 15/16
    line-height: 1.333333333;
  }
}
.contact-page-description p {
  font-size: 0.9375rem !important; // 15/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    font-size: 1.125rem !important; // 18/16
  }
}