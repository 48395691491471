html {
  font-size: $font-size-base;
}
body {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
  &:hover {
    border-color: darken($ks_lt_blue, 10%);
    transition: all 0.3s ease-out;
  }
}
h1 {
  line-height: 1.23952381;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media all and (min-width: $mobile-desktop-breakpoint) {
    max-width: 50%;
    font-size: floor($font-size-base * 2.5);
    .single-post & {
      max-width: none;
    }
  }
}
h3 {
  text-transform: uppercase;
  letter-spacing: 0.075em;
  @media all and (min-width: $desktop-breakpoint-1) {
    font-size: 2.375rem; // 38/16
  }
}
main.main {
  p {
    font-weight: 300;
    font-size: 1rem; // 16/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      font-size: 1.375rem; // 22/16
    }
  }
}
.max-content-width {
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
}
small {
  font-size: 0.8125rem; // 13/16
  font-weight: 300;
}
p.intro-text {
  line-height: 1.675675676;
  font-weight: 300;
  font-size: 1.375rem !important; // 22/16
  @media (min-width: $mobile-desktop-breakpoint) {
    font-size: 2rem !important; // 32/16
  }
}
ul {
  padding-left: 0;
  list-style: none;
  li::before {
    content: '+ ';
    font-weight: 900;
    margin-right: 0.5em;
    color: $ks_dk_blue;
  }
  .areaDescription & {
    li {
      margin-bottom: 0.5em;
    }
    li::before {
      color: $ks_white;
    }
  }
}
.btn {
  white-space: normal;
}