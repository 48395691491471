.single {
	article {
		img {
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}
}
article {
	margin-bottom: 6rem;
	header {
		display: block;
		margin-bottom: 1rem;
		.single & {
			margin-bottom: 3rem;
		}
		a {
			display: inline-block;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.entry-title {
		letter-spacing: 0.05em;
		margin-bottom: 0;
		font-size: 1.5rem; // 24/16
		font-weight: 900;
		text-transform: none;
		line-height: 1.2;
		.single & {
			font-size: 2rem;
		}
		a {
			display: inline;
		}
	}
	.byline {
	  display: block;
	  letter-spacing: 0.05em;
	  text-transform: uppercase;
	  font-size: 1.25rem; // 20/16
	  font-weight: 900;
	}
}
.readmore {
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 0.05em;
}
.nav-links {
	margin-top: 2rem;
	margin-bottom: 2rem;
	.nav-previous, .nav-next {
		display: block;
		margin-bottom: 1rem;
	}
	@media all and (min-width: $mobile-desktop-breakpoint) {
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);
		display: block;
		.nav-previous, .nav-next {
			display: inline-block;
			margin-bottom: 1rem;
			width: calc(50% - 30px);
			margin-left: 15px;
			margin-right: 15px;
		}
		.nav-previous {
			float: left;
		}
		.nav-next {
			float: right;
		}
	}
	img {
		display: block;
		margin-bottom: 1rem;
	}
	a {
	  transition: all 0.3s ease-out;
	  text-transform: uppercase;
	  letter-spacing: 0.05em;
	  line-height: 1.1;
	  font-weight: 500;
	  font-size: 1rem; // 16/16
	  width: 100%;
	  display: inline-block;
	  .post-title {
	  	border-bottom: 2px solid transparent;
	  	transition: all 0.3s ease-out;
	  }
	  &:hover {
	  	.post-title {
	  		border-color: darken($ks_lt_blue, 10%);
		  	transition: all 0.3s ease-out;
	  	}
	  }
	  @media all and (min-width: $mobile-desktop-breakpoint) {
	    font-size: 1.1675rem; // 18.68/16
	    display: inline;
	    width: auto;
	  }
	  @media all and (min-width: $desktop-breakpoint-1) {
	    font-size: 1.5625rem; // 25/16
	  }
	}
}
.posts-navigation {
	.nav-links {
		margin-top: 2rem;
		margin-bottom: 2rem;
		max-width: 45.625rem; // 730/16
		margin-left: 0;
		margin-right: 0;
		.nav-previous, .nav-next {
			display: block;
			margin-bottom: 1rem;
			@media all and (min-width: $desktop-breakpoint-1) {
				width: auto;
				margin-left: auto;
				margin-right: auto;
			}
		}
		@media all and (min-width: $mobile-desktop-breakpoint) {
			.nav-previous {
				float: left;
			}
			.nav-next {
				float: right;
			}
		}
		a {
		  border: 6px solid $ks_dk_blue;
		  background-color: $ks_white;
		  color: $ks_dk_blue;
		  transition: all 0.3s ease-out;
		  text-transform: uppercase;
		  letter-spacing: 0.2em;
		  padding: 0.125em 2.5em;
		  font-weight: 900;
		  font-size: 1rem; // 16/16
		  width: 100%;
		  display: block;
		  @media all and (min-width: $mobile-desktop-breakpoint) {
		    font-size: 1.1675rem; // 18.68/16
		    display: inline;
		    width: auto;
		  }
		  @media all and (min-width: $desktop-breakpoint-1) {
		    font-size: 1.5625rem; // 25/16
		  }
		  &:hover {
		    background-color: $ks_dk_blue;
		    color: $ks_white;
		    transition: all 0.3s ease-out;
		  }
		}
	}

}
.blog-disclaimer p {
	font-style: italic;
	font-size: 0.85rem !important;
	margin-top: 2rem;
	margin-bottom: 3rem;
	@media all and (min-width: $mobile-desktop-breakpoint) {
		font-size: 1rem !important;
	}
	@media all and (min-width: $desktop-breakpoint-1) {
		font-size: 1.25rem !important;
	}
}

@media all and (min-width: $desktop-breakpoint-1) {
	.blog-index {
		margin-left: -30px;
		margin-right: -30px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		article {
			width: calc( 50% - 60px );
			margin: 30px;
			display: inline-block;
		}
	}
}
.home-blog-feat-img:hover {
	border-color: transparent;
}