header.banner {
  background-color: lighten($ks_dk_blue, 50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  .contact &, .disclaimer &, .practice-areas &, .single-practice-area &, .blog &, .single & {
    height: 10.375rem; // 166/16
    background-color: $ks_white;
    @media all and (min-width: $mobile-desktop-breakpoint) {
      height: 16.25rem; // 260/16
    }
  }
  .home &, .professionals &, .single-staff & {
    min-height: 30rem; // 480/16
  }
  .single-practice-area & {
    background-color: $ks_dk_blue;
  }
  width: 100%;
  text-align: center;
  .ks-nav-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.75rem; // 60/16
    padding-bottom: 3.75rem; // 60/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      padding-top: 5.875rem; // 94/16
      padding-bottom: 5.875rem; // 94/16
    }
  }
}

// Logo/Brand
.brand {
  position: relative;
  z-index: 1000;
  display: block;
  width: 2.625rem; // 42/16
  height: 2.875rem; // 46/16
  border: 0;
  @media all and (min-width: $mobile-desktop-breakpoint) {
    width: 15rem; // 240/16
    height: 4.5rem; // 72/16
  }
  svg path {
    fill: $ks_dk_blue;
    transition: fill 0.3s ease-out;
    .home &, .professionals &, .single-practice-area &, .single-staff & {
      fill: $ks_white;
    }
  }
  &:hover {
    border: 0;
    svg path {
      fill: $ks_lt_blue;
      transition: fill 0.3s ease-out;
    }
  }
}
.svg-logo-header-mobile {
  display: block;
  width: 100%;
  height: auto;
  max-width: 2.625rem; // 42/16
  max-height: 2.875rem; // 46/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    display: none;
  }
}
.svg-logo-header-desktop {
  display: none;
  @media all and (min-width: $mobile-desktop-breakpoint) {
    display: block;
    width: 100%;
    height: auto;
    max-width: 15rem; // 240/16
    max-height: 4.5rem; // 72/16
  }
}

// Navigation
.ks-nav-btns {
  height: 10.5625rem; // 169/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    height: 16.25rem; // 260/16
  }
}
.ks-nav {
  position: fixed;
  z-index: 990;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($ks_lt_blue, 0.9);
  color: $ks_white;
  display: none;
  padding-top: 6.484375rem; // 103.75/16
  a {
    color: $ks_white;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.1em;
    font-size: 1.5rem; // 24/16
    @media all and (min-width: $sub-mobile-breakpoint) {
      // font-size: 2rem; // 32/16
    }
    @media all and (min-width: $mobile-desktop-breakpoint) {
      font-size: 3rem; // 48/16
    }
    @media all and (min-width: $desktop-breakpoint-1) {
      font-size: 4rem; // 64/16
      margin: 2rem;
    }
    border: 0;
    &:hover {
      color: $ks_dk_blue;
      border: 0;
    }
  }
  li::before {
    content: '';
    margin: 0;
  }
}
.menu-toggle {
  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox]:checked ~ .ks-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  label {
    position: relative;
    z-index: 1000;
    text-transform: uppercase;
    text-align: center;
    width: 3.5em;
    overflow: hidden;
    letter-spacing: 0.1em;
    font-weight: 900;
    font-style: normal;
    font-size: 1.5625rem; // 25/16
    margin-bottom: -1rem;
    color: $ks_dk_blue;
    .home &, .professionals &, .single-practice-area &, .single-staff & {
      color: $ks_white;
    }
    transition: color 0.3s ease-out;
    @media all and (min-width: $mobile-desktop-breakpoint) {
      font-size: 1.90625rem; // 30.5/16
    }
    &:hover {
      color: $ks_lt_blue;
      transition: color 0.3s ease-out;
      cursor: pointer;
    }
  }
  input[type=checkbox] {
    & + label .to-open {
      display: block;
      // text-align: right;
    }
    & + label .to-close {
      display: none;
    }
  }
  input[type=checkbox]:checked {
    & + label .to-open {
      display: none;
    }
    & + label .to-close {
      display: block;
      color: $ks_white;
      font-size: 1.5em;
      &:hover {
        color: $ks_dk_blue;
      }
    }
  }
}