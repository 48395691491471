// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

@media (max-width: $mobile-desktop-breakpoint) {
  div[class^="col"] {
    padding-left: $side-padding-mobile; 
    padding-right: $side-padding-mobile;
  }
  .container-fluid, .main {
    padding-left: $side-padding-mobile; 
    padding-right: $side-padding-mobile;
  }
  .row {
    margin-left: -$side-padding-mobile;
    margin-right: -$side-padding-mobile;
  }
}