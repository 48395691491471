.contact {
  .wpcf7 {
    margin-top: 2rem;
    @media all and (min-width: $mobile-desktop-breakpoint) {
      margin-top: 0;
    }
  }
}
.googlemap {
  width: 100%;
  height: 100vw;
  @media (min-width: $sub-mobile-breakpoint) {
    height: 50vw;
  }
}