.home {
  header.banner {
    position: relative;
    // @include bgimg('header_bg_home', 768, 992, 1200);
  }
  .wrap {
    background-color: $ks_dk_blue;
    color: $ks_white;
    padding-top: 3.75rem; // 60/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      padding-top: 5rem; // 80/16
    }
    .btn {
      border: 0;
      margin-top: 2rem;
      &:hover {
        background-color: $ks_lt_blue;
        color: $ks_white;
      }
    }
  }
}
.blog-bar {  
  padding-top: 3.9375rem; // 63/16
  padding-bottom: 2.0625rem; // 33/16
  .home-blogpost {
    margin-bottom: 4rem;
  }
  .home-blog-feat-img {
    width: 100%;
    display: block;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .byline {
    font-weight: 900;
    text-transform: uppercase;
  }
  @media all and (min-width: $desktop-breakpoint-1) {
    padding-top: 5.8125rem; // 93/16
    padding-bottom: 8.3125rem; // 133/16
  }
}