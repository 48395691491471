.professionals {  
  header.banner {
    position: relative;
    @include bgimg('header_bg_professionals', 768, 992, 1200);
  }
  .page-tagline {
    span {
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0.05em;
      line-height: 1.5;
      font-size: 1.375rem; // 22/16
      max-width: ($max-content-width * 0.85);
      @media all and (min-width: $mobile-desktop-breakpoint) {
        font-size: 2rem; // 32/16
      }
    }
  }
  .wrap {
    padding-top: 3.75rem; // 60/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      padding-top: 5rem; // 80/16
    }
    .btn {
      margin-top: 2rem;
      &:hover {
        border-color: $ks_dk_blue;
      }
    }
  }
}
.staffListing-container {
  position: relative;
  margin-left: -($side-padding-mobile / 2);
  margin-right: -($side-padding-mobile / 2);
  @media all and (min-width: $desktop-breakpoint-3) {
    margin-left: -12.9375rem; // 207/16
    margin-right: -12.9375rem;
  }
}
.staffListing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 4rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-content-width;
}
.staffMember-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: ($side-padding-mobile / 2);
  text-align: center;
  img {
    width: 130%;
    margin-left: -15%;
    margin-right: -15%;
  }
  @media all and (min-width: $sub-mobile-breakpoint) {
    width: 50%; 
  }
  @media all and (min-width: $mobile-desktop-breakpoint) {
    width: 33.3333333%; 
  }
  @media all and (min-width: $desktop-breakpoint-1) {
    width: 25%; 
  }
}
.staffMember {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  height: 0;
  overflow: hidden;
  display: block;
  border: 0 !important;
  &:hover {
    border: 0 !important;
    .staffMemberInfo {
      background-color: rgba($ks_dk_blue, 0.9);
      transition: background-color 0.3s ease-out;
    }
  }
}
.staffMemberInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;
  color: $ks_white;
  margin-right: $side-padding-mobile;
  background-color: rgba($ks_lt_blue, 0.7);
  transition: background-color 0.3s ease-out;
  line-height: 1.390946502;
  font-size: 1rem;
  padding: 1rem;
  height: 6em; // 96/16
  .single-staff & {
    font-size: 1.25rem; // 20/16
    padding: 2rem;
    top: -11.25rem; // 120px(6em) + 60px = -180/16
    padding-left: $side-padding-mobile;
    @media all and (min-width: $mobile-desktop-breakpoint) {
      top: -13rem; // 128(5.333333333em) + 80 = 208/16
      width: 50%;
      left: $side-padding-mobile;
      font-size: 1.5rem;
      height: 7.5em;
    }
    @media all and (min-width: $desktop-breakpoint-3) {
      top: -17rem; // 192 + 80 = 272/16
      left: -(2 * $side-padding-mobile);
    }
    @media all and (min-width: $desktop-breakpoint-3) {
      top: -16rem; // 176 + 80 = 256/16
      font-size: 2.4375rem; // 39/16
      height: 6.5em;
    }
  }
  .staffName {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .staffTitle {
    font-weight: 300;
    letter-spacing: 0.075em;
  }
}
.single-staff {
  .wrap {
    padding-top: 3.75rem; // 60/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      padding-top: 5rem; // 80/16
    }
  }
}
.staffMemberModule {
  padding-top: 1rem;
  padding-bottom: 1rem;
  h3 {
    margin-top: 0;
  }
  @media (min-width: $desktop-breakpoint-1) {
    padding-top: 2rem; 
    padding-bottom: 2rem;
  }
}
.staffMemberBio {
  p {
    font-style: italic;
    font-size: 1.25rem !important;
  }
  @media all and (min-width: $mobile-desktop-breakpoint) {
    padding-top: 3rem;
    p {
      font-size: 2rem !important;
    }
  }
}
.staffMemberPracticeAreas {
  ul {
    font-size: 1.25rem; // 20/16
  }
}
.staffMemberEmailLink {
  .btn {
    &:hover {
      border-color: $ks_dk_blue;
    }
  }
}
.staff-contact-icons {
  div {
    margin-right: 0.625rem; // 10/16
  }
}
.vcard, .staffLinkedIn {
  // margin-bottom: 20px;
  display: block;
  float: left;
  svg {
    display: block;
    path {
      fill: $ks_dk_blue;
      transition: fill 0.3s ease-out;
    }
  }
  &:hover {
    svg path {
      fill: $ks_lt_blue;
      transition: fill 0.3s ease-out;
    }
  }
}
.vcard svg {
    width: 4.0625rem; // 65/16
    height: 4.0625rem; // 65/16
}
.staffLinkedIn svg {
    width: 2.8125rem; // 45/16
    height: 2.8125rem; // 45/16
    margin: 0.625rem 0; // 10/16
}
.related-blog-posts {
  font-weight: 300;
  font-size: 1rem; // 16/16
  li {
    margin-bottom: 1em;
  }
  a {
    font-weight: 500;
  }
  @media all and (min-width: $mobile-desktop-breakpoint) {
    font-size: 1.375rem; // 22/16
  }
}
// David Craig passed away and they want to hide the link to his bio page
a.david_v_craig {
  &:hover {
    cursor: default !important;
    .staffMemberInfo {
      background-color: rgba($ks_lt_blue, 0.7) !important;
    }
  }
}