.btn {
  border: 6px solid $ks_dk_blue;
  background-color: $ks_white;
  color: $ks_dk_blue;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0.125em 2.5em;
  font-weight: 900;
  font-size: 1.1675rem; // 18.68/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    font-size: 1.5625rem; // 25/16
  }
  &:hover {
    background-color: $ks_dk_blue;
    color: $ks_white;
    transition: all 0.3s ease-out;
  }
}