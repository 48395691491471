.page-header {
  border: 0;
}
.max-content-width-body {
  max-width: ($max-content-width * 0.85);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem; // 80/16
  @media all and (min-width: $mobile-desktop-breakpoint) {
    margin-bottom: 10rem; // 160/16
  }
}
.page-tagline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  .home &, .professionals & {
    min-height: 30rem; // 480/16
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10.375rem; // 166/16
  padding-left: $side-padding-mobile;
  padding-right: $side-padding-mobile;
  span {
    position: relative;
    z-index: 989;
    color: $ks_white;
    max-width: $max-content-width;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.1em;
    line-height: 1.355075792;
    text-align: left;
    font-size: 2.375rem; // 38/16
    @media all and (min-width: $sub-mobile-breakpoint) {
      font-size: 2.625rem; // 42/16
    }
    @media all and (min-width: $mobile-desktop-breakpoint) {
      font-size: 5rem; // 70/16
    }
    @media all and (min-width: $desktop-breakpoint-1) {
      font-size: 5.9375rem; // 95/16
    }
  }
  p {
    margin-bottom: 0;
  }
}