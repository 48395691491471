footer.content-info {
  padding-top: 4.375rem; // 70/16
  padding-bottom: 3.125rem; // 50/16
  background-color: $ks_dk_blue;
  color: $ks_white;
  font-size: 1.0625rem; // 17/16
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.075em;
  line-height: 1.588235294; // 27/17
  a {
    font-weight: 900;
  }
  h3 {
    font-size: 1.625135428em; // 30/18.46
    text-transform: uppercase;
    color: $ks_lt_blue;
    letter-spacing: 0.12em;
    max-width: 5em;
    margin-top: 0;
    margin-bottom: 1.2em;
  }
  .widget_text {
    p {
      margin-bottom: 0;
    }
  }
}
.disclaimer-link {
  text-transform: uppercase;
  font-size: 1.055254605em; // 19.48/18.46
  margin-bottom: 5.875rem; // 94/16
  @media screen and (min-width: $mobile-desktop-breakpoint) {
    margin-top: 5.3125rem; // 85/16
    margin-bottom: 0;
  }
}
.social-links {
  text-align: left;
  padding-top: 3.125rem; // 50/16
  padding-bottom: 2.75rem; // 44/16
  @media screen and (min-width: $mobile-desktop-breakpoint) {
    margin-top: 5.625rem; // 90/16
    text-align: right;
    padding: 0;
  }
  a {
    width: 3.25rem; // 52/16
    height: 3.25rem; // 52/16
    border: 0;
    &:hover {
      border: 0;
      svg path {
        fill: rgba($ks_grey, 0.75);
        transition: fill 0.3s ease-out;
      }
    }
  }
  svg {
    width: 100%;
    height: 100%;
    max-width: 3.25rem; // 52/16
    max-height: 3.25rem; // 52/16
    path {
      fill: rgba($ks_grey, 0.5);
      transition: fill 0.3s ease-out;
    }
  }
}
.footer-copyright {
  font-size: 0.75rem; // 12/16
  color: rgba($ks_grey, 0.6);  
  @media screen and (min-width: $mobile-desktop-breakpoint) {
    margin-top: 2.875rem; // 46/16
  }
}