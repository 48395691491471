.single-practice-area {
  .wrap {
    background-color: $ks_dk_blue;
    color: $ks_white;
    overflow: hidden;
  }
  .areaIntro {
    margin-bottom: 2rem;
    p {
      line-height: 1.675675676;
      font-weight: 300;
      font-size: 1.375rem !important; // 22/16
      @media (min-width: $mobile-desktop-breakpoint) {
        font-size: 2rem !important; // 32/16
      }
    }
  }
  .areaDescription {
    font-size: 0.9375rem; // 15/16
    line-height: 1.8;
    @media all and (min-width: $mobile-desktop-breakpoint) {
      font-size: 1.25rem; // 20/16
    }
  }
  .practiceArea-staff {
    background-color: $ks_white;
    color: $ks_dk_blue;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-top: 5rem; // 80/16
    padding-bottom: 5rem; // 80/16
    margin-bottom: -5rem; // 80/16
    margin-top: 5rem; // 80/16
    @media all and (min-width: $mobile-desktop-breakpoint) {
      margin-bottom: -10rem; // 160/16
      margin-top: 10rem; // 160/16
    }
  }
  .staffListing-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $side-padding-mobile/2;
    padding-right: $side-padding-mobile/2;
    max-width: 100vw;
    h3 {
      width: 100%;
      max-width: $max-content-width;
      margin: 0 auto;
      padding-left: $side-padding-mobile/2;
      padding-right: $side-padding-mobile/2;
    }
    .staffListing {
      margin-top: 1rem;
    }
  }
}
.practice-area-listing {
  margin-top: 4rem;
  .panel {
    border: 0;
  }
  .panel-heading {
    padding: 0;
  }
  .panel-title {
    border: 0;
    .btn {
      width: 100%;
      text-align: left;
      padding-left: 1em;
      padding-right: 1em;
      color: $ks_dk_blue;
      background-color: $ks_white;
      border-color: $ks_dk_blue;
      letter-spacing: 0.1em;
      &:hover {
        border-color: $ks_lt_blue;
        background-color: $ks_lt_blue;
        color: $ks_white;
      }
    }
  }
  .panel-default {
    box-shadow: none;
    padding: 0.4rem 0;
  }
  .panel-body {
    border: 0px !important;
    padding: 0.625rem 0;
    margin-left: -0.625rem; // 10/16
    margin-right: -0.625rem; // 10/16
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    a {
      display: block;
      width: 100%;
      height: 5rem;
      margin: 0.625rem; // 10/16
      padding: 1em;
      background-color: $ks_dk_blue;
      border: 0;
      color: $ks_white;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      font-size: 0.75rem; // 12/16
      &:hover {
        background-color: $ks_lt_blue;
        border: 0;
      }
      @media all and (min-width: $sub-mobile-breakpoint) {
        width: calc( 50% - 20.5px ); // slightly more than 20px bc ipad issue
      }
      @media all and (min-width: $desktop-breakpoint-1) {
        font-size: 0.875rem; // 14/16
      }
      @media all and (min-width: $desktop-breakpoint-2) {
        font-size: 1.25rem; // 20/16
        height: 10rem;
      }
    }
  }
}