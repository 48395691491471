.staff-header-img {
  width: 100%;
  height: 500px;
  display: block;
  background-color: lighten($ks_dk_blue, 60%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.disableClick {
  pointer-events: none;
  color: rgba($ks_dk_blue, 0.5);
  border-color: rgba($ks_dk_blue, 0.5);
  &:hover {
    cursor: not-allowed !important;
    background-color: rgba($ks_dk_blue, 0.5);
    border-color: rgba($ks_dk_blue, 0.5) !important;
  }
}